.changeable {
    color: rgb(0, 0, 0);
    background-color: rgb(223, 234, 255);
    // font-weight: 550;
}

.changeable:hover {
    transition: 400ms;
    background-color: rgb(31, 31, 112);
    color: white;
}

.unchangeable {
    transition: 400ms;
}