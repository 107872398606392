@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Esteban:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Esteban:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

:root {
  --background: white;
  --text-primary: rgb(60, 60, 60);
  --text-secondary: rgb(120, 120, 120);
  --accent: rgb(214, 241, 236);
}
[data-theme="dark"] {
  --background: rgb(30, 30, 30);
  --text-primary: white;
  --text-secondary: rgb(200, 200, 200);
  --accent: rgb(42, 63, 58);
}


.main{color:var(--text-primary);background-color:var(--background);font-size:large;font-weight:bold;padding:20px;height:calc(100vh - 40px);transition:all .5s}.switcher{color:var(--text-primary);background-color:var(--background);float:right;transition:all .5s;outline:none;border:none}.icon{color:var(--text-primary)}.home-button{float:right}.header{padding-bottom:48px}.mid-section{max-width:600px;width:80%;margin:0 auto;font-size:16px;font-weight:400;line-height:24px}.links-section{justify-content:space-between}.section{display:flex}.section-title{flex-grow:0;min-width:40px;padding-right:32px;color:var(--text-secondary)}.section-text{flex-grow:1}.link{color:inherit;padding-right:12px}.styled-link{color:inherit;text-decoration:none;background-color:var(--accent)}.citsec:hover{transition:400ms;background-color:#112188;color:#fff}.jane-street:hover{transition:400ms;background-color:#3c7eb8;color:#fff}.bloomberg:hover{transition:400ms;background-color:#1c1c1c;color:#fff}.shopify:hover{transition:400ms;background-color:#2b9c6d;color:#fff}.waterloo:hover{transition:400ms;background-color:#a1981a;color:#fff}.cats:hover{transition:400ms;background-color:#ce74ce;color:#fff}

.unlock{color:#282828;font-family:"Esteban",serif;display:flex;flex-direction:column;margin:0 auto;max-width:720px;padding-left:6%;padding-right:6%;padding-top:50px;padding-bottom:50px;line-height:26pt}.input-area{outline:1px solid #1f1f70;width:-webkit-max-content;width:max-content}.input-bar{max-width:360px;line-height:26pt;font-size:18pt;font-family:"Esteban",serif;border:none}.input-bar:focus{outline:none}.submit{line-height:30pt;outline:1px solid #1f1f70;border:none;font-size:18pt;color:#000;background-color:#dfeaff}.submit:hover{transition:400ms;background-color:#1f1f70;color:#fff}
.setting{color:#282828;font-family:"Esteban",serif}img{height:200px;border-radius:4px}.letter{margin:0 auto;max-width:720px;padding-left:6%;padding-right:6%;padding-top:50px;padding-bottom:50px;line-height:26pt}.title{font-weight:600;font-size:large}
.changeable{color:#000;background-color:#dfeaff}.changeable:hover{transition:400ms;background-color:#1f1f70;color:#fff}.unchangeable{transition:400ms}
