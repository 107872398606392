.main {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  padding: 20px;
  height: calc(100vh - 40px);
  transition: all 0.5s;
}

.switcher {
  color: var(--text-primary);
  background-color: var(--background);
  float: right;
  transition: all 0.5s;
  outline: none;
  border: none;
}

.icon {
  color: var(--text-primary);
}

.home-button {
  float: right;
}

.header {
  padding-bottom: 48px;
}

.mid-section {
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.links-section {
  justify-content: space-between;
}

.section {
  display: flex;
}

.section-title {
  flex-grow: 0;
  min-width: 40px;
  padding-right: 32px;
  color: var(--text-secondary);
}

.section-text {
  flex-grow: 1;
}

.link {
  color: inherit;
  padding-right: 12px;
}

.styled-link {
  color: inherit;
  text-decoration: none;
  background-color: var(--accent);
}

.citsec:hover {
  transition: 400ms;
  background-color: rgb(17, 33, 136);
  color: white;
}

.jane-street:hover {
  transition: 400ms;
  background-color: rgb(60, 126, 184);
  color: white;
}

.bloomberg:hover {
  transition: 400ms;
  background-color: rgb(28, 28, 28);
  color: white;
}

.shopify:hover {
  transition: 400ms;
  background-color: rgb(43, 156, 109);
  color: white;
}

.waterloo:hover {
  transition: 400ms;
  background-color: rgb(161, 152, 26);
  color: white;
}

.cats:hover {
  transition: 400ms;
  background-color: rgb(206, 116, 206);
  color: white;
}
