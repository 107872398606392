@import url('https://fonts.googleapis.com/css2?family=Esteban:wght@400&display=swap');

.setting {
    color: rgb(40, 40, 40);
    font-family: 'Esteban', serif;
}

img {
    height: 200px;
    border-radius: 4px;
}

.letter {
    // font-family: "Times New Roman", Times, serif;
    margin: 0 auto;
    max-width: 720px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 50px;
    padding-bottom: 50px;
    line-height: 26pt;
}

.title {
    font-weight: 600;
    font-size: large;
}